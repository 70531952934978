<template>
  <div>
    <div class="text-not-selected container">
      <!-- 头部logo -->
      <div class="logo ">
        <img alt="logo" src="@/assets/images/logo.png" />
        <span>石上良品</span>
      </div>

      <!--  右上角标 -->
      <div class="mark" @click="isWxLogin = !isWxLogin"></div>
      <div class="mark-txt" @click="isWxLogin = !isWxLogin">{{ isWxLogin ? '账号' : '微信' }}</div>

      <!-- 微信二维码 -->
      <div class="qrcode-block" v-if="isWxLogin">
        <iframe class="qrcode" :src="wxURL" frameborder="0" scrolling="no"></iframe>
      </div>

      <a-form :form="form" v-else class="form">
        <a-form-item name="phoneNumber">
          <a-input
            @keyup.enter.native="handleLogin"
            placeholder="手机号"
            v-decorator="['phoneNumber', { rules: [{ required: true, pattern: /^1\d{10}$/, message: '请输入正确的手机号' }] }]"
          />
        </a-form-item>
        <a-form-item name="password">
          <a-input
            @keyup.enter.native="handleLogin"
            placeholder="密码"
            type="password"
            v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }] }]"
          />
        </a-form-item>
        <a-row>
          <a-button type="primary" @click="handleLogin">
            登录
          </a-button>
        </a-row>
      </a-form>
    </div>
    <div class="footer">
      <div class="copyright">
        石上良品  ©2022
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/js/wxLogin'
export default {
  name: 'Login',

  data () {
    return {
      form: this.$form.createForm(this, { name: 'Login' }),
      isWxLogin: false,
      wxQRCode: {
        selfRedirect: false,
        appid: 'wxf77d881d4256a271',
        scope: 'snsapi_login',
        redirectURI: 'https://www.nextstone.vip/wx-login',
        state: '',
        style: '',
        href: 'data:text/css;base64,I3RwbF9mb3JfaWZyYW1lLC5pbXBvd2VyQm94LC5pbXBvd2VyQm94IC5sb2dpblBhbmVsLC5pbXBvd2VyQm94IC5wYW5lbENvbnRlbnQsLmltcG93ZXJCb3ggLnFyY29kZSwuaW1wb3dlckJveCAud2FpdGluZywuaW1wb3dlckJveCAud3JwX2NvZGUsLndlYl9xcmNvZGVfdHlwZV9pZnJhbWUsYm9keSxodG1se3dpZHRoOjIwMHB4O2hlaWdodDoyMDBweDttYXJnaW46MH0uaW1wb3dlckJveCAuaW5mbywuaW1wb3dlckJveCAudGl0bGV7ZGlzcGxheTpub25lfQ=='
      }
    }
  },

  computed: {
    wxURL () {
      return `https://open.weixin.qq.com/connect/qrconnect?appid=${this.wxQRCode.appid}&scope=${this.wxQRCode.scope}&redirect_uri=${encodeURIComponent(this.wxQRCode.redirectURI)}&state=${this.wxQRCode.state}&login_type=jssdk&self_redirect=${this.wxQRCode.selfRedirect}&styletype=${this.wxQRCode.style}&sizetype=&bgcolor=&rst=&href=${encodeURIComponent(this.wxQRCode.href)}`
    }
  },

  mounted () {
    if (this.$store.state.token) return this.$router.replace(this.$store.state.currentPath)
  },

  methods: {
    // 登录
    handleLogin () {
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }

        this.api.login(values).then(res => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
            return
          }
          this.$store.commit('setToken', res.data)

          // 获取管理信息，包含权限
          this.api.getUserInfo().then(res2 => {
            const info = res2.data.getInfoByUser
            if (!info) return this.$message.error('登录失败：获取信息失败')
            this.$store.commit('setUserInfo', info)
            this.$router.replace(this.$store.state.currentPath)
          })
        })
      })
    }
  }
}
</script>

<style scoped>
.container {
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -170px 0 0 -160px;
  width: 320px;
  height: 340px;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0,0,0,0.08);
}

.mark {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border-right: 80px solid var(--blue-color);
  border-bottom: 80px solid transparent;
}
.mark-txt {
  position: absolute;
  top: 13px;
  right: 10px;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.logo {
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo img {
  width: 40px;
  margin-right: 12px;
  box-shadow: 0 0 10px 1px rgba(0,0,0,0.1);
  border-radius: 50%;
}
.logo span {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
  color: var(--blue-color);
}
.ant-spin-container, .ant-spin-nested-loading {
  height: 100%;
}
.footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.copyright {
  margin: 48px 0 24px 0;
  padding: 0 16px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
}

.form {
  margin-top: 40px;
}
.form button {
  width: 100%;
}
.form p {
  color: #cccccc;
  text-align: center;
  margin-top: 16px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

.qrcode-block {
  width: 100%;
  display: flex;
  justify-content: center;
}
.qrcode {
  width: 200px;
  height: 200px;
}
</style>
